import React from "react";
import './Carousel.scss';
const Carousel = ({ children }) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const activeSlide = children.map((slide, index) => (React.createElement("div", { "data-active": currentSlide === index, className: `carousel-slide ${currentSlide === index ? 'active' : ''}`, key: index }, slide)));
    const handleClickprev = () => {
        setCurrentSlide((currentSlide - 1 + activeSlide.length) % activeSlide.length);
    };
    const handleClicknext = () => {
        setCurrentSlide((currentSlide + 1) % activeSlide.length);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "product-item-image" },
            React.createElement("div", { className: "product-item-image-container" },
                React.createElement("div", { className: "carousel-wrapper" },
                    React.createElement("div", { style: { display: 'flex', transition: 'all 0.5s ease', transform: `translateX(-${currentSlide * 100}%)` } }, activeSlide))),
            React.createElement("div", { className: "carousel-buttons" },
                React.createElement("button", { className: "carousel-button left", onClick: handleClickprev },
                    React.createElement("img", { src: "/assets/arrow-left.svg" })),
                React.createElement("button", { className: "carousel-button right", onClick: handleClicknext },
                    React.createElement("img", { src: "/assets/arrow-right.svg" }))))));
};
export default Carousel;
