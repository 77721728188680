import { getChoicesQuantity, getFreeChoices, getFreeIncluded, haveFreeProducts, haveIncludedProducts, isFreeChoicesStockValid, isGroupProduct, ProductType, convertToEuros, isB2B, isChoiceProductType, getTotalProductPrice } from "common";
import { Decrement, Increment } from "components/Quantity";
import React, { useEffect } from "react";
import Img from "components/Img/Img";
import "./CartItem.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsAttempt } from "store/products/productActions";
import { selectProductsWithLeaves } from "store/products/productSelectors";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import { FormattedMessage, useIntl } from "react-intl";
function CartItem({ increment, decrement, remove, editable, product, findIndex }) {
    var _a, _b, _c, _d, _e, _f;
    const dispatch = useDispatch();
    const intl = useIntl();
    const products = useSelector(selectProductsWithLeaves);
    const profile = useSelector(selectProfileInfos);
    const priceKey = !!profile && isB2B(profile) ? 'price' : 'b2cPrice';
    const productPrice = product === null || product === void 0 ? void 0 : product[priceKey];
    useEffect(() => {
        dispatch(fetchProductsAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "k-cartitem" },
        React.createElement("div", { className: "k-cartitem--image" }, ((_a = product === null || product === void 0 ? void 0 : product.imageUrl) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
            React.createElement(Img, { src: `${product === null || product === void 0 ? void 0 : product.imageUrl[0]}`, name: `${product.name}` })),
        React.createElement("div", { className: "k-cartitem--name" },
            React.createElement("strong", null,
                React.createElement(FormattedMessage, { id: product.name, defaultMessage: product.name })),
            !isChoiceProductType(product) && (isGroupProduct(product) ?
                React.createElement(React.Fragment, null,
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: (_b = product === null || product === void 0 ? void 0 : product.element) === null || _b === void 0 ? void 0 : _b.name, defaultMessage: (_c = product === null || product === void 0 ? void 0 : product.element) === null || _c === void 0 ? void 0 : _c.name })),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "Prix unitaire : ", defaultMessage: "Prix unitaire : " }),
                        " ",
                        convertToEuros(productPrice),
                        React.createElement(FormattedMessage, { id: "\u20AC HT", defaultMessage: "\u20AC HT" })))
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "Prix unitaire : ", defaultMessage: "Prix unitaire : " }),
                            " ",
                            convertToEuros(productPrice * (product.type === ProductType.SCALE_GROUP && !product.restricted ? getChoicesQuantity(product) : 1)),
                            React.createElement(FormattedMessage, { id: "\u20AC HT", defaultMessage: "\u20AC HT" }))))),
        React.createElement("div", { className: "k-cartitem--gridmaker" },
            editable && React.createElement("div", { className: "k-cartitem--quantity" },
                (product === null || product === void 0 ? void 0 : product.quantity) <= 1 && React.createElement("button", { className: "k-btn--icon icon-bin", type: "button", onClick: () => remove(product) }, "\u00A0"),
                (product === null || product === void 0 ? void 0 : product.quantity) > 1 && React.createElement(Decrement, { decrement: () => decrement(product) }),
                React.createElement("span", null,
                    "x ", product === null || product === void 0 ? void 0 :
                    product.quantity),
                (product === null || product === void 0 ? void 0 : product.type) !== ProductType.SIMPLE_PRODUCT && React.createElement("span", { onClick: findIndex },
                    React.createElement(Link, { to: `/nos-produits/${product === null || product === void 0 ? void 0 : product.slug}`, className: "k-btn--icon icon-pen" }, "\u00A0")),
                (product === null || product === void 0 ? void 0 : product.type) === ProductType.SIMPLE_PRODUCT && React.createElement(Increment, { increment: () => increment(product) })),
            !editable && React.createElement("div", { className: "k-cartitem--quantity" },
                React.createElement("span", null,
                    " x ", product === null || product === void 0 ? void 0 :
                    product.quantity)),
            React.createElement("div", { className: "k-cartitem--price" },
                convertToEuros(getTotalProductPrice(product, priceKey)),
                "\u20AC ",
                React.createElement(FormattedMessage, { id: "HT", defaultMessage: "HT" }))),
        (product === null || product === void 0 ? void 0 : product.choices.length) > 0 &&
            React.createElement("div", { className: "k-cartitem--choices" }, (_d = product === null || product === void 0 ? void 0 : product.choices) === null || _d === void 0 ? void 0 : _d.map((choice, index) => {
                return React.createElement("div", { className: "k-cartitem--choices_item", key: index },
                    React.createElement("figure", null,
                        React.createElement(Img, { src: `${choice === null || choice === void 0 ? void 0 : choice.imageUrl}`, name: `${choice === null || choice === void 0 ? void 0 : choice.name}` })),
                    React.createElement(FormattedMessage, { id: choice === null || choice === void 0 ? void 0 : choice.name, defaultMessage: choice === null || choice === void 0 ? void 0 : choice.name }),
                    React.createElement("b", null,
                        " x ", choice === null || choice === void 0 ? void 0 :
                        choice.quantity));
            })),
        React.createElement("table", null,
            haveFreeProducts(product) && ((_e = getFreeChoices(product)) === null || _e === void 0 ? void 0 : _e.ids.map((choice, index) => {
                return React.createElement("tr", { key: index }, choice.quantity > 0 && React.createElement(React.Fragment, null,
                    React.createElement("td", null, choice.name),
                    " ",
                    React.createElement("td", null,
                        " x ",
                        !isFreeChoicesStockValid(choice, products) ? choice.quantity + ' ' + intl.formatMessage({ id: 'dont', defaultMessage: 'dont' }) + ' ' + (choice.quantity - (choice.stock > 0 ? choice.stock : 0)) + ' ' + intl.formatMessage({ id: 'reliquat(s)', defaultMessage: 'reliquat(s)' }) : choice.quantity),
                    React.createElement("td", null,
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "OFFERT", defaultMessage: "OFFERT" })))));
            })),
            haveIncludedProducts(product) && ((_f = getFreeIncluded(product)) === null || _f === void 0 ? void 0 : _f.ids.map((choice, index) => {
                return React.createElement("tr", { key: index },
                    React.createElement("td", null,
                        React.createElement(FormattedMessage, { id: choice === null || choice === void 0 ? void 0 : choice.name, defaultMessage: choice === null || choice === void 0 ? void 0 : choice.name })),
                    " ",
                    React.createElement("td", null,
                        " x ",
                        !isFreeChoicesStockValid(choice, products) ? choice.quantity + ' ' + intl.formatMessage({ id: 'dont', defaultMessage: 'dont' }) + ' ' + (choice.quantity - (choice.stock > 0 ? choice.stock : 0)) + ' ' + intl.formatMessage({ id: 'reliquat(s)', defaultMessage: 'reliquat(s)' }) : choice.quantity),
                    React.createElement("td", null,
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "INCLUS", defaultMessage: "INCLUS" }))));
            })))));
}
export default CartItem;
