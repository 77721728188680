import { getChoicesQuantity, ProductType, convertToEuros, BusinessType } from 'common';
import { CheckMarkAnimation } from 'components/Animations/CheckMarkAnimation';
import { Button } from 'components/Form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStep from 'shared/hooks/useStep';
import { fetchOrdersAttempt } from 'store/account/orders/ordersActions';
import { selectOrders } from 'store/account/orders/ordersSelectors';
import { addToCart } from 'store/cart/cartActions';
import { selectProductAddedToCart } from 'store/cart/cartSelectors';
import "./Commandes.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from 'react-router-dom';
function Commandes() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [activeStep, setActiveStep] = useStep();
    const orders = useSelector(selectOrders);
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const loadCart = (products) => {
        for (const product of products) {
            if (!product.expired) {
                dispatch(addToCart(product));
            }
        }
    };
    const isReusableOrder = (products) => {
        return products.filter(product => product.expired).length === products.length;
    };
    useEffect(() => {
        dispatch(fetchOrdersAttempt());
    }, [dispatch]);
    return (React.createElement("section", { className: "k-account--section" },
        React.createElement("header", null,
            React.createElement("h1", null,
                React.createElement(FormattedMessage, { id: "Vos commandes", defaultMessage: "Vos commandes" })),
            React.createElement("p", null,
                React.createElement(FormattedMessage, { id: "Historique de mes commandes et factures", defaultMessage: "Historique de mes commandes et factures" }),
                ".")),
        React.createElement("div", { className: "k-account--orders" }, orders.length > 0 && orders.map((order) => {
            var _a;
            return React.createElement("div", { key: order.orderId, className: `k-orderitem ${activeStep === order.orderId ? 'open' : ''}`, onClick: () => setActiveStep(order.orderId) },
                React.createElement("div", { className: "k-orderitem--toggle" },
                    React.createElement("b", null, order.createdAt),
                    "#",
                    order.orderId),
                React.createElement("div", { className: "k-orderitem--content" },
                    order.products.length
                        ? React.createElement("div", { className: "k-orderitem--content_items" },
                            order.products.map((product, index) => {
                                return (React.createElement("div", { className: "k-orderitem--content_item", key: index },
                                    React.createElement("span", { className: product.expired ? 'expired' : '' },
                                        React.createElement(FormattedMessage, { id: product.name, defaultMessage: product.name }),
                                        " x ",
                                        product.quantity),
                                    React.createElement("span", null,
                                        (convertToEuros(order.businessType === BusinessType.B2B ? product.price : product.b2cPrice
                                            * (product.type === ProductType.CHOICE_PRODUCT && !product.restricted || product.type === ProductType.SCALE_GROUP ? getChoicesQuantity(product) : 1)
                                            * product.quantity)),
                                        "\u20AC")));
                            }),
                            React.createElement("div", { className: "k-orderitem--content_item" },
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "Total", defaultMessage: "Total" })),
                                React.createElement("span", null,
                                    convertToEuros(order.total),
                                    "\u20AC ",
                                    React.createElement(FormattedMessage, { id: "HT", defaultMessage: "HT" }))))
                        : React.createElement("div", null,
                            React.createElement(FormattedMessage, { id: "Aucune commande pass\u00E9e pour le moment", defaultMessage: "Aucune commande pass\u00E9e pour le moment" })),
                    React.createElement("div", { className: "k-orderitem--content_actions" },
                        ((_a = order === null || order === void 0 ? void 0 : order.billing) === null || _a === void 0 ? void 0 : _a.viewUrl) !== undefined
                            ?
                                React.createElement(Link, { to: order.billing.viewUrl, target: '_blank', className: 'k-btn k-btn--white' },
                                    React.createElement(FormattedMessage, { id: "T\u00E9l\u00E9charger ma facture", defaultMessage: "T\u00E9l\u00E9charger ma facture" }))
                            : '',
                        React.createElement(Button, { className: "k-btn k-btn--white", buttonName: intl.formatMessage({ id: "Replacer dans le panier", defaultMessage: "Replacer dans le panier" }), disabled: isReusableOrder(order.products), onClick: () => { loadCart(order.products); } }))));
        })),
        React.createElement(CheckMarkAnimation, { message: intl.formatMessage({ id: "Produit(s) ajouté(s) à votre panier !", defaultMessage: "Produit(s) ajouté(s) à votre panier !" }), isActive: productAddedToCart })));
}
export default Commandes;
