import { BusinessType, convertToEuros, getChoicesQuantity, ProductType } from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStep from "shared/hooks/useStep";
import { fetchQuotationsAttempt } from "store/account/quotations/quotationsActions";
import { selectQuotations } from "store/account/quotations/quotationsSelectors";
import { addToCart } from "store/cart/cartActions";
import { Button } from "components/Form";
import { CheckMarkAnimation } from "components/Animations/CheckMarkAnimation";
import { selectProductAddedToCart } from "store/cart/cartSelectors";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from 'react-router-dom';
function Devis() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [activeStep, setActiveStep] = useStep();
    const quotations = useSelector(selectQuotations);
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const loadCart = (products) => {
        for (const product of products) {
            if (!product.expired) {
                dispatch(addToCart(product));
            }
        }
    };
    const isReusableOrder = (products) => {
        return products.filter(product => product.expired).length === products.length;
    };
    useEffect(() => {
        dispatch(fetchQuotationsAttempt());
    }, [dispatch]);
    return (React.createElement(React.Fragment, null,
        React.createElement("section", { className: "k-account--section" },
            React.createElement("header", null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Vos paniers enregistr\u00E9s", defaultMessage: "Vos paniers enregistr\u00E9s" })),
                quotations.length > 0 ? (React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Vous n'avez pas effectu\u00E9 de sauvegarde de panier", defaultMessage: "Vous n'avez pas effectu\u00E9 de sauvegarde de panier" }),
                    ".")) : (React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Liste de devis effectu\u00E9s en sauvegardant le panier", defaultMessage: "Liste de devis effectu\u00E9s en sauvegardant le panier" }),
                    "."))),
            React.createElement("div", { className: "k-account--orders" }, quotations.length > 0 && quotations.map((quotation) => {
                var _a;
                return (React.createElement("div", { key: quotation.quotationId, className: `k-orderitem ${activeStep === quotation.quotationId ? 'open' : ''}`, onClick: () => setActiveStep(quotation.quotationId) },
                    React.createElement("div", { className: "k-orderitem--toggle" },
                        React.createElement("b", null, quotation.createdAt),
                        "#",
                        quotation.quotationId),
                    React.createElement("div", { className: "k-orderitem--content" },
                        quotation.products.length ? (React.createElement("div", { className: "k-orderitem--content_items" },
                            quotation.products.map((product, index) => (React.createElement("div", { className: "k-orderitem--content_item", key: index },
                                React.createElement("span", { className: product.expired ? 'expired' : '' },
                                    React.createElement(FormattedMessage, { id: product.name, defaultMessage: product.name }),
                                    " x ",
                                    product.quantity),
                                React.createElement("span", null,
                                    (convertToEuros(quotation.businessType === BusinessType.B2B
                                        ? product.price
                                        : product.b2cPrice *
                                            (product.type === ProductType.CHOICE_PRODUCT && !product.restricted ||
                                                product.type === ProductType.SCALE_GROUP
                                                ? getChoicesQuantity(product)
                                                : 1) *
                                            product.quantity)),
                                    "\u20AC")))),
                            React.createElement("div", { className: "k-orderitem--content_item" },
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "Total", defaultMessage: "Total" })),
                                React.createElement("span", null,
                                    convertToEuros(quotation.total),
                                    "\u20AC ",
                                    React.createElement(FormattedMessage, { id: "HT", defaultMessage: "HT" }))))) : (React.createElement("div", null,
                            React.createElement(FormattedMessage, { id: "Aucun devis pour le moment", defaultMessage: "Aucun devis pour le moment" }))),
                        React.createElement("div", { className: "k-orderitem--content_actions" },
                            ((_a = quotation === null || quotation === void 0 ? void 0 : quotation.quotation) === null || _a === void 0 ? void 0 : _a.viewUrl) !== undefined ? (React.createElement(Link, { to: quotation.quotation.viewUrl, target: "_blank", className: "k-btn k-btn--white" },
                                React.createElement(FormattedMessage, { id: "T\u00E9l\u00E9charger mon devis", defaultMessage: "T\u00E9l\u00E9charger mon devis" }))) : (''),
                            React.createElement(Button, { className: "k-btn k-btn--white", buttonName: intl.formatMessage({ id: "Replacer dans le panier", defaultMessage: "Replacer dans le panier" }), disabled: isReusableOrder(quotation.products), onClick: () => {
                                    loadCart(quotation.products);
                                } })))));
            }))),
        React.createElement(CheckMarkAnimation, { message: intl.formatMessage({ id: "Produit(s) ajouté(s) à votre panier !", defaultMessage: "Produit(s) ajouté(s) à votre panier !" }), isActive: productAddedToCart })));
}
export default Devis;
