import React from "react";
import { connect } from 'react-redux';
import Register from '../Register/Register';
import Initialize from './Initialize';
import Recover from "./Recover";
function Recovery({ query }) {
    return (React.createElement("div", { className: "auth container" },
        React.createElement("div", { className: "columns is-multiline is-mobile is-centered" },
            React.createElement("div", { className: "column is-one-quarter black-block" },
                React.createElement("div", { className: "content" }, query.token
                    ? React.createElement(Recover, null)
                    : React.createElement(Initialize, null))),
            React.createElement("div", { className: "column is-full white-block opacity back-to-auth is-half" },
                React.createElement(Register, null)))));
}
const mapStateToProps = (state) => ({
    query: state.router.location.query
});
export default connect(mapStateToProps)(Recovery);
