import React from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Input, InputError } from "components/Form";
import Button from "components/Form/Buttons/Button";
import { selectErrors, selectPending } from "common";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { renewPasswordAttempt } from "store/account/profile/profileActions";
import { selectChangedPassword } from "store/account/profile/profileSelectors";
import { FormattedMessage, useIntl } from "react-intl";
function Recover({ query }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const apiErrors = useSelector(selectErrors('RENEW_PASSWORD'));
    const pending = useSelector(selectPending('RENEW_PASSWORD'));
    const passwordChanged = useSelector(selectChangedPassword);
    const onSubmit = (values) => {
        dispatch(renewPasswordAttempt(values));
    };
    return (React.createElement("div", { className: "login has-text-centered" },
        React.createElement(Formik, { validateOnChange: true, initialValues: {
                password: '',
                password_confirmation: '',
                token: query.token || ''
            }, enableReinitialize: true, onSubmit: onSubmit },
            React.createElement(Form, null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Modifier votre mot de passe :", defaultMessage: "Modifier votre mot de passe :" })),
                React.createElement(Input, { name: "email", type: "email", label: intl.formatMessage({ id: 'Email', defaultMessage: 'Email' }), className: "white" }),
                React.createElement(Input, { name: "password", type: "password", label: intl.formatMessage({ id: 'Mot de passe', defaultMessage: 'Mot de passe' }), className: "white" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password }),
                React.createElement(Input, { name: "password_confirmation", type: "password", label: intl.formatMessage({ id: 'Confirmation du mot de passe', defaultMessage: 'Confirmation du mot de passe' }), className: "white" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password_confirmation }),
                React.createElement(Button, { loading: pending, buttonName: intl.formatMessage({ id: "Envoyer", defaultMessage: "Envoyer" }), className: "orange" }),
                React.createElement("div", { style: { display: passwordChanged ? 'block' : 'none' } },
                    React.createElement("small", null,
                        React.createElement(FormattedMessage, { id: "Le mot de passe a \u00E9t\u00E9 modifi\u00E9", defaultMessage: "Le mot de passe a \u00E9t\u00E9 modifi\u00E9" }))),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.token }),
                React.createElement("small", null,
                    React.createElement(Link, { to: "/authentification" },
                        React.createElement(FormattedMessage, { id: "Connexion", defaultMessage: "Connexion" })))))));
}
const mapStateToProps = (state) => ({
    query: state.router.location.query
});
export default connect(mapStateToProps)(Recover);
