import { ProductType, selectIsAuthenticated, } from "common";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { fetchProductAttempt } from "store/products/productActions";
import { selectProduct } from "store/products/productSelectors";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import { addToCart, editToCart, selectProductToModify } from "store/cart/cartActions";
import Img from "components/Img/Img";
import "./ProductDetail.scss";
import useModal from "shared/hooks/useModal";
import { truncate } from "shared/helpers";
import { ReviewWidget } from "components/Review/ReviewWidget";
import { selectCartProducts, selectProductAddedToCart, selectProductIndexToModify } from "store/cart/cartSelectors";
import { CheckMarkAnimation } from "components/Animations/CheckMarkAnimation";
import { ProductForm } from "../Form/ProductForm";
import Carousel from "../components/Carousel";
import CarouselSlide from "../components/CarouselSlide";
import { FormattedMessage, useIntl } from "react-intl";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import { selectTags } from "store/tags/tagSelectors";
import { fetchTagsAttempt } from "store/tags/tagActions";
import ProductListItem from "../components/ProductListItem";
function ProductDetail() {
    var _a;
    const [MODAL_ID] = useState('freeProductsModal');
    const [reviewId, setReviewId] = useState('');
    const { slug } = useParams();
    const dispatch = useDispatch();
    const { toggleModal, closeModal } = useModal();
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const productIndexToModify = useSelector(selectProductIndexToModify);
    const products = useSelector(selectCartProducts);
    const selectedProductToModify = products[productIndexToModify];
    const product = useSelector(selectProduct(slug));
    const user = useSelector(selectProfileInfos);
    const tags = useSelector(selectTags);
    function slugify(text) {
        const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
        const to = "aaaaaeeeeeiiiiooooouuuunc------";
        const newText = text.split('').map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));
        return newText
            .toString()
            .toLowerCase()
            .trim()
            .replace('#', '-')
            .replace(/\s+/g, '-')
            .replace(/&/g, '-y-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-');
    }
    useEffect(() => {
        dispatch(fetchTagsAttempt());
        dispatch(fetchProductAttempt(slug));
        const currentTag = tags.find(obj => obj._id === product.tag);
        console.log('currentTag', currentTag);
        return () => {
            dispatch(selectProductToModify(null));
        };
    }, [dispatch, slug, isAuthenticated]);
    useEffect(() => {
        setReviewId(product === null || product === void 0 ? void 0 : product.reviewId);
    }, [product]);
    const onSubmit = (cartProduct) => {
        !!selectedProductToModify
            ? dispatch(editToCart(Object.assign(Object.assign({}, cartProduct), { quantity: +cartProduct.quantity })))
            : dispatch(addToCart(Object.assign(Object.assign({}, cartProduct), { quantity: +cartProduct.quantity })));
        closeModal(MODAL_ID);
    };
    const intl = useIntl();
    console.log(product);
    return (React.createElement("div", { className: "k-product" }, !!product && !!tags && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "k-product--breadcrumb" },
            React.createElement("ol", { className: "k-topbar--items" },
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Accueil", to: "/" }, "Accueil")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Produits", to: "/nos-produits" }, "Produits")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": tags.find(obj => obj._id === product.tag).name, to: `/nos-produits/categorie/` + slugify(tags.find(obj => obj._id === product.tag).name) }, tags.find(obj => obj._id === product.tag).name)),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": product.name, to: `/nos-produits/${product.slug}` }, product.name)))),
        React.createElement("div", { className: "k-product--detail" },
            React.createElement("div", { className: "k-product--detail_viz" }, product.imageUrl && product.imageUrl.length === 1 ?
                React.createElement("div", { className: "k-product--img" },
                    React.createElement(Img, { src: product.imageUrl[0], name: product.name, alt: product.name }))
                :
                    React.createElement(Carousel, null, product === null || product === void 0 ? void 0 : product.imageUrl.map((img) => (React.createElement(CarouselSlide, { key: img, imageUrl: img }))))),
            React.createElement("div", { className: "k-product--detail_data" },
                React.createElement("div", null,
                    React.createElement(Helmet, null,
                        React.createElement("title", null, product.name),
                        React.createElement("meta", { name: "description", content: truncate(`${product === null || product === void 0 ? void 0 : product.description}`, 150) }),
                        React.createElement("script", { type: "application/ld+json" }, `{
                  "@context": "https://schema.org/",
                  "@type": "Product",
                  "name": "${product.name}",
                  "image": ["${product.imageUrl[0]}"],
                  "description": "${product.description}",
                  "sku": "${product.code}",
                  "brand": {
                    "@type": "Brand",
                    "name": "KUST"
                  }
                }`)),
                    React.createElement("h1", { className: "k-product--title" },
                        React.createElement(FormattedMessage, { id: product.name, defaultMessage: product.name })),
                    product.promoText &&
                        React.createElement("div", { className: "k-product--discount" },
                            React.createElement("span", null, product.promoText)),
                    React.createElement("div", { className: "k-product--description", dangerouslySetInnerHTML: { __html: product.description } }),
                    !!product.informations &&
                        React.createElement("div", { className: "k-product--complement more-infos" },
                            React.createElement("p", null, truncate(product.informations, 50)),
                            !!product.informationsLink && React.createElement(Link, { to: `${product === null || product === void 0 ? void 0 : product.informationsLink}` },
                                React.createElement(FormattedMessage, { id: "En savoir plus", defaultMessage: "En savoir plus" }))))),
            React.createElement("section", { className: "k-product--order", id: "k-anchor--1" },
                React.createElement("header", null,
                    React.createElement("h2", null, "Votre s\u00E9lection"),
                    !isAuthenticated &&
                        React.createElement(React.Fragment, null,
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "Pour consulter les tarifs, vous devez \u00EAtre un.e professionnel.l.e enregistr\u00E9.e puis connect\u00E9.e.", defaultMessage: "Pour consulter les tarifs, vous devez \u00EAtre un.e professionnel.l.e enregistr\u00E9.e puis connect\u00E9.e." }),
                                React.createElement("br", null),
                                React.createElement(FormattedMessage, { id: "Utilisez le bouton de connexion en bas de l'\u00E9cran.", defaultMessage: "Utilisez le bouton de connexion en bas de l'\u00E9cran." })),
                            !!product.button &&
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "ou :", defaultMessage: "ou :" }),
                                    React.createElement(Link, { to: `${product === null || product === void 0 ? void 0 : product.buttonLink}` },
                                        React.createElement(FormattedMessage, { id: "Je suis un particulier", defaultMessage: "Je suis un particulier" }))))),
                React.createElement(Formik, { validateOnChange: true, initialValues: Object.assign(Object.assign({}, product), { quantity: product.type === ProductType.SIMPLE_PRODUCT ? 0 : 1, element: !!selectedProductToModify ? selectedProductToModify === null || selectedProductToModify === void 0 ? void 0 : selectedProductToModify.element : null, choices: !!selectedProductToModify ? selectedProductToModify === null || selectedProductToModify === void 0 ? void 0 : selectedProductToModify.choices : product === null || product === void 0 ? void 0 : product.choices }), enableReinitialize: true, onSubmit: (values, actions) => {
                        onSubmit(values);
                        actions.resetForm();
                    } },
                    React.createElement(ProductForm, { product: product, setReviewId: setReviewId, modalId: MODAL_ID, toggleModal: toggleModal })),
                React.createElement(ReviewWidget, { reviewId: reviewId, product: product })),
            product.userGuide && product.userGuide.trim() !== '' && (React.createElement("section", { className: "k-product--guide" },
                React.createElement("header", null,
                    React.createElement("h2", null,
                        React.createElement("i", null, "Nos conseils"),
                        "d'utilisation")),
                React.createElement("div", { className: "k-product--guide_content", dangerouslySetInnerHTML: { __html: product.userGuide } }))),
            ((_a = product === null || product === void 0 ? void 0 : product.products) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                React.createElement("section", { className: "k-product--suggestions k-highlight k-highlight--black" },
                    React.createElement("header", { className: "k-highlight--header" },
                        React.createElement("h2", null,
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "Nos produits", defaultMessage: "Nos produits" })),
                            React.createElement("i", null,
                                React.createElement(FormattedMessage, { id: "compl\u00E9mentaires", defaultMessage: "compl\u00E9mentaires" }))),
                        product.complementaryText && React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: product.complementaryText, defaultMessage: product.complementaryText }))),
                    React.createElement("div", { className: "k-highlight--items" }, product === null || product === void 0 ? void 0 : product.products.map((similar) => {
                        return (React.createElement(ProductListItem, { framed: true, path: `/nos-produits/${similar.slug}`, product: similar }));
                    }))),
            React.createElement(CheckMarkAnimation, { message: intl.formatMessage({ id: "Produit(s) ajouté(s) à votre panier !", defaultMessage: "Produit(s) ajouté(s) à votre panier !" }), isActive: productAddedToCart }))))));
}
export default ProductDetail;
