import { Countries, getTotalPriceByCountry, convertToEuros, convertFromVatToHT } from "common";
import React from "react";
import { useSelector } from "react-redux";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import './Summary.scss';
import { FormattedMessage } from "react-intl";
export function Summary({ title, children, cart, removePromotion }) {
    const user = useSelector(selectProfileInfos);
    return (React.createElement("div", { className: "k-cart--aside_data" },
        React.createElement("strong", null, title),
        React.createElement("table", null,
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement(FormattedMessage, { id: "PanierHT : ", defaultMessage: "Panier HT :" })),
                    React.createElement("td", null,
                        convertToEuros(cart.total),
                        "\u20AC")),
                !!cart.promotion && cart.products.length > 0 &&
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            React.createElement(FormattedMessage, { id: "Remise : ", defaultMessage: "Remise :" }),
                            " ",
                            cart.promotion.title),
                        React.createElement("td", null,
                            "- ",
                            convertToEuros(cart.promoReduction),
                            "\u20AC"),
                        typeof removePromotion === 'function' && React.createElement("td", null,
                            React.createElement("span", { onClick: removePromotion }, "x"))),
                typeof cart.fees === 'number' &&
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            React.createElement(FormattedMessage, { id: "Frais de livraison : ", defaultMessage: "Frais de livraison : " })),
                        cart.fees > 0 ?
                            React.createElement("td", null,
                                convertToEuros(convertFromVatToHT(cart.fees)),
                                "\u20AC")
                            :
                                React.createElement("td", { style: { textAlign: "right" } },
                                    React.createElement(FormattedMessage, { id: "Offerts", defaultMessage: "Offerts" }),
                                    React.createElement("br", null),
                                    React.createElement("small", null,
                                        "(",
                                        React.createElement(FormattedMessage, { id: "min achat 200\u20AC", defaultMessage: "min achat 200\u20AC" }),
                                        ")"))),
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement(FormattedMessage, { id: "Sous total HT : ", defaultMessage: "Sous total HT :" })),
                    React.createElement("td", null,
                        convertToEuros(cart.totalWithPromo + (convertFromVatToHT(cart.fees || 0))),
                        "\u20AC")),
                (user === null || user === void 0 ? void 0 : user.country) === Countries.FRANCE
                    ?
                        React.createElement(React.Fragment, null,
                            React.createElement("tr", null,
                                React.createElement("td", null,
                                    React.createElement(FormattedMessage, { id: "TVA : ", defaultMessage: "TVA :" })),
                                React.createElement("td", null,
                                    convertToEuros(getTotalPriceByCountry(cart, user.country) - (cart.totalWithPromo + (convertFromVatToHT(cart.fees || 0)))),
                                    "\u20AC")),
                            React.createElement("tr", null,
                                React.createElement("td", null,
                                    React.createElement(FormattedMessage, { id: "Total TTC : ", defaultMessage: "Total TTC :" })),
                                React.createElement("td", null,
                                    convertToEuros(getTotalPriceByCountry(cart, user.country)),
                                    "\u20AC")))
                    :
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                React.createElement(FormattedMessage, { id: "Total HT : ", defaultMessage: "Total HT :" })),
                            React.createElement("td", null,
                                convertToEuros(getTotalPriceByCountry(cart, user === null || user === void 0 ? void 0 : user.country)),
                                "\u20AC")))),
        children));
}
